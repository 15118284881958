import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Map from '../components/map/map'
import { Grid, Typography, Box, Button, Icon } from '@material-ui/core'
import Products from '../components/products/products'
import { graphql } from 'gatsby'

const IndexPage = props => {
  const hotProducts = props.data.allContentfulProducts.edges

  return (
    <Layout breadCrumbs={[]} isMainPage={true}>
      <SEO title="Гидроизоляционные смеси. Гидроизоляция бетона в Томске. Гидроизоляция купить в Томске." />
      <Grid container>
        <Box mb={3}>
          <Typography variant="h4" component="h2">
            Наша лучшая продукция
          </Typography>
        </Box>
        <Products products={hotProducts} />
      </Grid>
      <Grid container justify="flex-end">
        <Grid item sm={12} justify="flex-end">
          <Box textAlign="right" mr={3}>
            <Button
              color="primary"
              endIcon={<Icon>arrow_right_alt</Icon>}
              href="/products"
            >
              Вся продукция
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        <Box mb={3}>
          <Typography variant="h4" component="h2">
            Карта проезда
          </Typography>
        </Box>
        <Map />
      </Grid>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allContentfulProducts(filter: { hotProduct: { eq: true } }) {
      edges {
        node {
          title
          slug
          caption
          image {
            title
            file {
              url
            }
          }
        }
      }
    }
  }
`

export default IndexPage
