import React from 'react'
import './map.scss'

const Map = () => {
  return (
    <div className="map">
      <div style={{ position: 'relative', overflow: 'hidden' }}>
        <a
          href="https://yandex.com/maps/org/sluzhba_spaseniya_betona/55226633531/?utm_medium=mapframe&utm_source=maps"
          style={{ color: '#eee', fontSize: '12px', position: 'absolute', top: '0px' }}
        >
          Служба спасения бетона
        </a>
        <a
          href="https://yandex.com/maps/67/tomsk/category/insulation_materials/184107689/?utm_medium=mapframe&utm_source=maps"
          style={{ color: '#eee', fontSize: '12px', position: 'absolute', top: '14px' }}
        >
          Изоляционные материалы в Томске
        </a>
        <a
          href="https://yandex.com/maps/67/tomsk/category/building_mixes/184107757/?utm_medium=mapframe&utm_source=maps"
          style={{ color: '#eee', fontSize: '12px', position: 'absolute', top: '28px' }}
        >
          Строительные смеси в Томске
        </a>
        <iframe
          src="https://yandex.com/map-widget/v1/?ll=84.942411%2C56.496607&mode=search&oid=55226633531&ol=biz&z=17.16"
          width="100%"
          height="400"
          allowFullScreen
          style={{ position: 'relative' }}
        ></iframe>
      </div>
    </div>
  );
};

export default Map
